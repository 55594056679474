define("discourse/plugins/discourse-click-o-matic/initializers/disable-discourse-composer-scroll-sync", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'click-o-matic-initializer',
    initialize() {
      // disable preview scroll (the scrollmap simply isn't good enough; 
      // and we don't need it due to this plugin's clicking functionality)
      const composerComponent = require("discourse/components/composer-editor").default;
      composerComponent.reopen({
        _syncEditorAndPreviewScroll($input, $preview) {
          // enable again with:
          // this._super(...arguments);
        }
      });
    }
  };
});