define("discourse/plugins/discourse-click-o-matic/lib/discourse-markdown/click-o-matic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    helper.registerOptions((opts, siteSettings) => {
      opts.features["click-o-matic"] = window.markdownitLineNumbers && !!siteSettings.enable_discourse_click_o_matic;
    });
    helper.allowList({
      custom(tag, name, value) {
        // Allow elements with a data-ln attribute that contains a number
        if (name === "data-ln") {
          return !!value.match(/^\d+$/);
        }
      }
    });
    if (window.markdownitLineNumbers) {
      helper.registerPlugin(window.markdownitLineNumbers);
    }
  }
});